// Note:
// _menu: nếu là các menu
// _act: nếu là các action của button, ...

export const GlobalPolcies = {
  logModules: {
    left_menu: {
      list_logs: '44', // web: menu left - Quản lý logs - danh sách
    },
    component_act: {
      component_view: '45', //web: Feature - Quản lý logs - Xem logs trong module
    },
  },
  taiChinhThuHocPhiModule: {
    left_menu: {
      cai_dat: '46', //web: menu left - Cài đặt
      thu_hoc_phi: '47', //web: menu left - Thu học phí
      xu_ly_trong_ngay: '48', //web: menu left - Xử lý trong ngày
      thong_ke_bao_cao: '55', //web: menu left - Thống kê - báo cáo
      thong_ke_bao_cao_menu: {
        bang_ke_xac_nhan_ngan_hang: '56', //web: menu left - Thống kê - báo cáo - Bảng kê xác nhận ngân hàng
        bang_ke_thu_tien: '57', //web: menu left - Thống kê - báo cáo - Bảng kê thu tiền
        bang_ke_hoan_hoc_phi: '58', //web: menu left - Thống kê - báo cáo - Bảng kê hoàn học phí
        bang_ke_bao_cao_chuyen_nganh: '59', //web: menu left - Thống kê - báo cáo - Bảng kê báo cáo chuyển ngành
        thong_ke_tong_hop: '83', //web: menu left - Thống kê - báo cáo - Thống kê tổng hợp
        thong_ke_chi_tiet: '82', //web: menu left - Thống kê - báo cáo - Thống kê chi tiết'
      },
      du_lieu_ke_hoach: '60', //web: menu left - Dữ liệu kế hoạch
      quan_ly_phan_quyen: '61', //web: menu left - Quản lý phân quyền
      quan_ly_phan_quyen_menu: {
        danh_sach_tai_khoan: '62', //web: menu left - Quản lý phân quyền - Danh sách tài khoản
        danh_sach_quyen: '63', //web: menu left - Quản lý phân quyền - Danh sách quyền
        danh_sach_apis: '64', //web: menu left - Quản lý phân quyền - Danh sách APIs
        danh_sach_menu_action: '65', //web: menu left - Quản lý phân quyền - Danh sách menu action
        ung_dung: '66', //web: menu left - Quản lý phân quyền - Ứng dụng
        tai_khoan_ca_nhan: '67', //web: menu left - Quản lý phân quyền - Tài khoản cá nhân
        ho_so_ca_nhan: '68', //web: menu left - Quản lý phân quyền - Hồ sơ cá nhân
        cai_dat: '69', //web: menu left - Quản lý phân quyền - Cài đặt
        cai_dat_menu: {
          danh_muc_loi_ngan_hang: '70', //web: menu left - Quản lý phân quyền - Cài đặt - Danh sách lỗi ngân hàng
        },
        import_excel: '71', //web: menu left - Quản lý phân quyền - Import Excel
        import_excel_menu: {
          import_excel_loai_1: '72', //web: menu left - Quản lý phân quyền - Import Excel - Import Excel loại 1
          import_excel_loai_2: '73', //web: menu left - Quản lý phân quyền - Import Excel - Import Excel loại 2
          thiet_lap_mau_import: '74', //web: menu left - Quản lý phân quyền - Import Excel - Thiết lập mẫu Import
        },
      },
    },
    actions: {
      thu_hoc_phi: {
        chi_tiet: {
          nghiep_vu: {
            xac_nhan_ngan_hang_tab_act: '49', //web: Feature - Thu học phí - Chi tiết - Nghiệp vụ - Xác nhận ngân hàng tab
            dong_hoc_phi_tab_act: '50', //web: Feature - Thu học phí - Chi tiết - Nghiệp vụ - Đóng học phí tab
            rut_hoc_phi_tab_act: '51', //web: Feature - Thu học phí - Chi tiết - Nghiệp vụ - Rút học phí tab
            chuyen_nganh_tab_act: '52', //web: Feature - Thu học phí - Chi tiết - Nghiệp vụ - Chuyển ngành tab
          },
          danh_sach_phieu_thu: {
            chinh_sua_phieu_thu_act: '53', //web: Feature - Thu học phí - Chi tiết - Danh sách phiếu thu - Chỉnh sửa
            huy_phieu_thu_act: '54', //web: Feature - Thu học phí - Chi tiết - Danh sách phiếu thu - Hủy phiếu thu
          },
        },
        them_moi_du_lieu_ke_hoach: '80' //web: action - Thêm mới dữ liệu kế hoạch
      },
      du_lieu_ke_hoach: {
        import_excel_button: '81' //web: action - Import excel thông tin hồ sơ tuyển sinh
      }
    },
  },
};
